<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12">
      <div>
        <div class="card">
          <div class="card-header" v-if="isAdmin">
            <a-button type="primary" @click="goToNew"> + Tambah Obat </a-button>
          </div>
          <div class="card-body">
            <TabelObat />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabelObat from '@/components/tables/obat'
import router from '@/router'

export default {
  components: {
    TabelObat,
  },
  data: function () {
    return {
      isAdmin: false,
    }
  },
  created() {
    this.getRole()
  },
  methods: {
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    goToNew() {
      router.push('/obat/tambah')
    },
  },
}
</script>
