<template>
  <div>
    <div class="mb-4">
      <a-table
        :columns="columns"
        :dataSource="items"
        :pagination="true"
        :class="$style.table"
        rowKey="id"
        :loading="isLoading"
      >
        <template slot="name" slot-scope="name, record">
          <div>
            <div v-if="isAdmin">
              <router-link
                :to="{ path: '/obat/details/' + record.id }"
                class="kit__utils__link font-size-16"
                >{{ record.name }}</router-link
              >
            </div>
            <div v-if="!isAdmin">{{ record.name }}</div>
            <!-- <div class="text-gray-4">{{record.tag? record.tag: '-'}}</div> -->
          </div>
        </template>
        <!-- <template slot="createdAt" slot-scope="createdAt">
          <div>
            <div>{{ createdAt? moment(createdAt).format('DD MMM YYYY') : "-" }}</div>
          </div>
        </template> -->
        <template slot="status" slot-scope="status">
          <div>
            <div>{{ status ? 'Aktif' : 'Tidak Aktif' }}</div>
          </div>
        </template>
        <template slot="description" slot-scope="description">
          <div>
            <div>{{ description ? description : '-' }}</div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { getAll } from '@/services/axios/api/obat'
import moment from 'moment'
const columns = [
  {
    title: 'Nama Obat',
    dataIndex: 'name',
    className: 'bg-transparent text-gray-6 width-50',
    fixed: 'left',
    scopedSlots: { customRender: 'name' },
    sorter: (a, b) => {
      return a.name.localeCompare(b.name)
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'status' },
    sorter: (a, b) => a.status - b.status,
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'description' },
    sorter: (a, b) => {
      return a.description.localeCompare(b.description)
    },
  },
]

export default {
  name: 'TabelObat',
  data: function () {
    return {
      columns,
      items: [],
      isLoading: true,
      isAdmin: false,
    }
  },
  created() {
    this.getListData()
    this.getRole()
  },
  methods: {
    moment,
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    async getListData() {
      const res = await getAll()
      this.isLoading = false
      this.items = res
    },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
